import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { ModalPaper } from '@/components/shared'
import { QuickbookLogin } from '@/components/shared'
import {GoogleLogin, useGoogleLogin} from 'react-google-login';
import { GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: '1rem',
  },
  headerRow: {
    alignItems: 'flex-end',
  },
  search: {
    marginBottom: '8px',
  },
  grid: {
    minHeight: '60vh',
    [theme.breakpoints.up('md')]: {
      minHeight: '600px',
    },
  },
  paper: {
    maxHeight: '200px',
  },
  modal: {
    zIndex: 2300,
  },
}))

export const LoginModal: React.FC = () => {
  const classes = useStyles()

  const responseGoogle = (response) => {
    if (response?.tokenObj?.id_token) {
      //localStorage.setItem('gtoken', response.tokenObj.id_token)
      console.log("is google signed in")
    }
  }

  return (
    <ModalPaper extraClassName={classes.paper} fullScreen open={true}>
      <>
        <div className={classes.headerContainer}>
          <Typography variant="h5">Please login</Typography>
        </div>
        <QuickbookLogin />
        <GoogleLogin
            clientId="783620283384-i72bmqspd6r3osuug67lqsofm5kbjjhn.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            isSignedIn={true}
            cookiePolicy={'single_host_origin'}
            scope={'openid'}
        />
      </>
    </ModalPaper>
  )
}
