import React from 'react'
import {
  Row,
  Spacer,
  ModalLoading,
  ModalErrorMessage,
} from '@/components/shared'
import { Grid, Divider, Button } from '@material-ui/core'
import { FormField } from '../FormField'
import { FormSelect } from '../FormSelect'
import { FormSectionTitle } from '../FormSectionTitle'
import { FormCheckbox } from '../FormCheckbox'
import { FormContext } from '@/contexts'
import {
  optionsCountries,
  optionsProvinces,
  optionsStates,
  VALUE_CANADA,
  DEFAULT_PROVINCE,
  DEFAULT_STATE,
} from './constants'
import { useCreate } from '@/hooks/customers'
import {Customer} from "../../../../common/models/Customer";
import {TaxCode} from "../../../../common/models/TaxCode";
interface AddCustomerFormProps {
  onClose()
  onCompleted(customer: Customer)
  taxCodes: TaxCode[]
}

interface FormFieldProps {}

interface FormValues {
  company: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  billingCity: string
  billingLine1: string
  billingPostalCode: string
  billingCountry: string
  billingProvince: string
  shippingCity: string
  shippingLine1: string
  shippingPostalCode: string
  shippingCountry: string
  shippingProvince: string
  taxCode: string
}

export const AddCustomerForm: React.FC<AddCustomerFormProps> = (
  props,
) => {
  const [
    shippingSameAsBilling,
    setShippingSameAsBilling,
  ] = React.useState(true)

  const {
    create: createCustomer,
    isLoading: isCreatingCustomer,
    errorMessage,
    clearErrorMessage,
  } = useCreate()

  const [formValues, setFormValues] = React.useState<
    Partial<FormValues>
  >({
    shippingCountry: VALUE_CANADA,
    billingCountry: VALUE_CANADA,
    shippingProvince: DEFAULT_PROVINCE,
    billingProvince: DEFAULT_PROVINCE,
    taxCode: '181',
  })

  const updateValue = (key: string, value: any) => {
    const n = { ...formValues }
    n[key] = value

    if (key === 'shippingCountry') {
      n.shippingProvince =
        value === VALUE_CANADA ? DEFAULT_PROVINCE : DEFAULT_STATE
    } else if (key === 'billingCountry') {
      n.billingProvince =
        value === VALUE_CANADA ? DEFAULT_PROVINCE : DEFAULT_STATE
    }

    setFormValues(n)
  }

  const onSave = async () => {
    const customer: Customer = {
      DisplayName: formValues.company,
      GivenName: formValues.firstName,
      FamilyName: formValues.lastName,
      PrimaryPhone: {
        FreeFormNumber: formValues.phoneNumber,
      },
      PrimaryEmailAddr: {
        Address: formValues.email,
      },
      CompanyName: formValues.company,
      BillAddr: {
        CountrySubDivisionCode: formValues.billingProvince,
        City: formValues.billingCity,
        PostalCode: formValues.billingPostalCode,
        Line1: formValues.billingLine1,
        Country: formValues.billingCountry,
      },
      DefaultTaxCodeRef: {
        value: formValues.taxCode,
        name: '',
      },
    }

    if (!shippingSameAsBilling) {
      customer.ShipAddr = {
        CountrySubDivisionCode: formValues.shippingProvince,
        City: formValues.shippingCity,
        PostalCode: formValues.shippingPostalCode,
        Line1: formValues.shippingLine1,
        Country: formValues.shippingCountry,
      }
    }

    const newCustomer = await createCustomer(customer)
    if (newCustomer) props.onCompleted(newCustomer)
  }

  return (
    <FormContext.Provider
      value={{
        values: formValues,
        updateValue,
        getValue: (key) => formValues[key],
      }}
    >
      <Grid
        container
        spacing={1}
        style={{ overflow: 'auto', marginBottom: '32px' }}
      >
        <FormSectionTitle title="Company" />
        <FormField label="Company" name="company" />
        <FormSectionTitle title="Contact" />
        <FormField label="First name" name="firstName" />
        <FormField label="Last name" name="lastName" />
        <FormField label="Email" type="email" name="email" />
        <FormField
          label="Phone number"
          type="tel"
          name="phoneNumber"
        />
        <FormSectionTitle title="Taxes" />
        <FormSelect
          label="Default tax code"
          options={props.taxCodes.map((t) => ({
            value: t.Id,
            label: t.Name,
          }))}
          name="taxCode"
        />
        <FormSectionTitle title="Billing address" />
        <FormField label="City" name="billingCity" />
        <FormField label="Line1" name="billingLine1" />
        <FormField label="PostalCode" name="billingPostalCode" />
        <FormSelect
          label="Country"
          options={optionsCountries}
          name="billingCountry"
        />
        <FormSelect
          label="Province/State"
          options={
            formValues.billingCountry === VALUE_CANADA
              ? optionsProvinces
              : optionsStates
          }
          name="billingProvince"
        />
        <FormSectionTitle title="Shipping address" />

        <FormCheckbox
          checked={shippingSameAsBilling}
          onChange={() =>
            setShippingSameAsBilling(!shippingSameAsBilling)
          }
          label="Same as shipping"
          xs={12}
          md={12}
        />
        <FormField
          label="City"
          disabled={shippingSameAsBilling}
          name="shippingCity"
        />
        <FormField
          label="Line1"
          disabled={shippingSameAsBilling}
          name="shippingLine1"
        />
        <FormField
          label="PostalCode"
          disabled={shippingSameAsBilling}
          name="shippingPostalCode"
        />
        <FormSelect
          label="Country"
          options={optionsCountries}
          disabled={shippingSameAsBilling}
          name="shippingCountry"
        />
        <FormSelect
          label="Province/State"
          options={
            formValues.shippingCountry === VALUE_CANADA
              ? optionsProvinces
              : optionsStates
          }
          disabled={shippingSameAsBilling}
          name="shippingProvince"
        />
      </Grid>
      <Spacer />
      <Divider />
      <Row>
        <Button variant="text" size="small" onClick={props.onClose}>
          Cancel
        </Button>
        <Spacer />
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={onSave}
        >
          Save
        </Button>
      </Row>
      <ModalLoading isOpen={isCreatingCustomer} />
      <ModalErrorMessage
        message={errorMessage}
        onClose={clearErrorMessage}
      />
    </FormContext.Provider>
  )
}
