import React from 'react'
import {
  Grid,
  GridSize,
  SwitchProps,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface FormFieldProps {
  label: string
}

export const FormCheckbox: React.FC<
  FormFieldProps &
    Partial<Record<Breakpoint, boolean | GridSize>> &
    SwitchProps
> = (props) => {
  const defaultProps = {
    xs: 12,
    md: 3,
  }

  const finalProps = { ...defaultProps, ...props }

  return (
    <Grid item {...(finalProps as any)}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            {...(finalProps as any)}
          />
        }
        label={props.label}
      />
    </Grid>
  )
}
