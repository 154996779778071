import getClient from './client'
import {Customer} from "../../common/models/Customer";

export const getList = async (
  offset: number,
  limit: number,
): Promise<Customer[]> => {
  const { data } = await getClient().get(
    `/customers/get?offset=${offset}&limit=${limit}`,
  )
  return data
}

export const getCount = async (): Promise<number> => {
  const { data } = await getClient().get(`/customers/getcount`)
  return data.count
}

export const createCustomer = async (
  customer: Customer,
): Promise<Customer> => {
  const { data } = await getClient().post(
    `/customers/create`,
    customer,
  )
  return data
}
