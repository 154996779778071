import React from 'react'
import {
  Modal,
  Paper,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { Column } from '@/components/shared'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    flexShrink: 1,
    padding: theme.spacing(1),

    display: 'flex',
    flexDirection: 'column',
    marginTop: '56px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: 'inherit',
    },
  },
}))

interface ModalErrorMessageProps {
  message?: string
  onClose()
}

export const ModalErrorMessage: React.FC<ModalErrorMessageProps> = (
  props,
) => {
  const styles = useStyles()

  return (
    <Modal open={!!props.message} style={{ zIndex: 3000 }}>
      <div className={styles.wrapper}>
        <Paper className={`${styles.paper}`}>
          <Column>
            <Typography variant="h4">Error</Typography>
            <Typography>{props.message}</Typography>
            <Button color="primary" onClick={props.onClose}>
              Ok
            </Button>
          </Column>
        </Paper>
      </div>
    </Modal>
  )
}
