import React from 'react'
import {
  Container,
  Paper,
  Typography,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Menu } from './Menu'
import MenuIcon from '@material-ui/icons/Menu'
import { useRouter } from 'next/router'
import { QuickbookLogin } from '@/components/shared'
import { AppContext, LayoutContext } from '@/contexts'

const useStyles = makeStyles((theme) => ({
  MenuIcon: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  container: {
    padding: 0,
    flexGrow: 1,
    flexShrink: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
}))

export const Layout: React.FC<{}> = (props) => {
  const router = useRouter()

  const theme = useTheme()
  const styles = useStyles()

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [
    hasPaddingOnLargeDevices,
    setHasPaddingOnLargeDevices,
  ] = React.useState(true)

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [pageTitle, setPageTitle] = React.useState("Jackie's Toolbox")

  const handleClick = () => {
    router.push('/')
  }

  return (
    <AppContext.Provider
      value={{
        pageTitle,
        setPageTitle,
      }}
    >
      <LayoutContext.Provider value={{ setHasPaddingOnLargeDevices }}>
        <div
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AppBar position="fixed" style={{ zIndex: 2100 }}>
            <Toolbar>
              <MenuIcon
                className={styles.MenuIcon}
                onClick={() => setIsMenuOpen((v) => !v)}
              />
              <Typography variant="h6" onClick={handleClick}>
                <span style={{ cursor: 'pointer' }}>{pageTitle}</span>
              </Typography>
            </Toolbar>
            <Menu
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </AppBar>

          <Container className={styles.container}>
            <div
              className="MuiToolbar-regular"
              style={{ width: '100%' }}
            ></div>
            <Paper
              elevation={isSmall ? 0 : 3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                minHeight: 0,
              }}
            >
              {props.children}
            </Paper>
          </Container>
        </div>
      </LayoutContext.Provider>
    </AppContext.Provider>
  )
}
