import React from 'react'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Fullscreen } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    flexShrink: 1,
    padding: theme.spacing(1),

    display: 'flex',
    flexDirection: 'column',
    marginTop: '56px',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 56px)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: 'inherit',
    },
  },
}))

interface ModalPaperProps {
  extraClassName?: string
  fullScreen?: boolean
}

export const ModalPaper: React.FC<ModalProps & ModalPaperProps> = (
  props,
) => {
  const styles = useStyles()

  const modalStyle = {}
  if (props.fullScreen) {
    modalStyle['zIndex'] = 3000
  }
  return (
    <Modal {...props} style={{ ...modalStyle }}>
      <div className={styles.wrapper}>
        <Paper className={`${styles.paper} ${props.extraClassName}`}>
          {props.children}
        </Paper>
      </div>
    </Modal>
  )
}
