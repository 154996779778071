import React from 'react'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import { CircularProgress } from '@material-ui/core'

interface ModalLoadingProps {
  isOpen: boolean
}
export const ModalLoading: React.FC<ModalLoadingProps> = (props) => {
  return (
    <Modal open={props.isOpen}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    </Modal>
  )
}
