import React from 'react'
import {
  getList,
  getCount,
  createCustomer,
} from '@/api-client/customers'
import { useIsLoggedIn } from '@/hooks/auth'
import { flatten } from 'lodash'
import {Customer} from "../../common/models/Customer";

export const useGetAll = () => {
  const isLoggedIn = useIsLoggedIn()
  const [customers, setCustomers] = React.useState<Customer[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const getallItems = async () => {
    const totalNbItems = await getCount()

    let index = 0
    const promises = []

    do {
      promises.push(getList(index * 1000, 1000))
      index += 1
    } while (index * 1000 < totalNbItems)

    const res = await Promise.all(promises)
    setCustomers(flatten(res))
  }

  React.useEffect(() => {
    if (!isLoggedIn) return
    setIsLoading(true)
    getallItems()
      .then(() => {})
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [isLoggedIn, setCustomers])

  return { customers, isLoading }
}

export const useCreate = () => {
  const isLoggedIn = useIsLoggedIn()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<
    string | null
  >(null)

  const create = async (customer: Customer): Promise<Customer> => {
    setIsLoading(true)
    try {
      return await createCustomer(customer)
    } catch (e) {
      if (e.response?.status === 400) {
        console.log(e.response.data.message)
        setErrorMessage(e.response.data.message)
      }
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const clearErrorMessage = () => setErrorMessage(null)

  return { create, isLoading, errorMessage, clearErrorMessage }
}
