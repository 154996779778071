import React from 'react'
import {
  Grid,
  TextField,
  GridSize,
  TextFieldProps,
} from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { FormContext } from '@/contexts'
interface FormFieldProps {
  name: string
}

export const FormField: React.FC<
  FormFieldProps &
    Partial<Record<Breakpoint, boolean | GridSize>> &
    TextFieldProps
> = (props) => {
  const { getValue, updateValue } = React.useContext(FormContext)

  const defaultProps = {
    xs: 12,
    md: 3,
  }

  const finalProps = { ...defaultProps, ...props }

  return (
    <Grid item {...(finalProps as any)}>
      <TextField
        value={getValue(props.name) || ''}
        onChange={(event) =>
          updateValue(props.name, event.target.value)
        }
        {...(finalProps as any)}
        fullWidth
      />
    </Grid>
  )
}
