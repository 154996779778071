import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

interface LoaderProps {
  visible: boolean
}

export const Loader: React.FC<LoaderProps> = ({ visible }) =>
  !visible ? null : (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
