import Cookies from 'js-cookie'
import getClient from './client'

export const getLoginUrl = async () => {
  const { data } = await getClient().get('auth/link')

  return data.link
}

export const callIsLoggedIn = async () => Cookies.get('token')

export const refreshToken = async () => {
  if (!localStorage.getItem('token')) return null

  const { data } = await getClient().get('auth/refresh')

  if (data.token) {
    localStorage.setItem('token', data.token)
  } else {
    localStorage.removeItem('token')
  }
}
