import React from 'react'
import { Grid, Typography } from '@material-ui/core'

interface FormFieldProps {
  title: string
}

export const FormSectionTitle: React.FC<FormFieldProps> = (props) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        marginTop: '8px',
        marginBottom: 0,
      }}
    >
      <Typography>{props.title}</Typography>
    </Grid>
  )
}
